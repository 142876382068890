export const seo = {
  url: 'logistyka',
  title: {
    pl: 'Logistyka | Definicja | Usługi | Magazynowanie | Ubezpieczenie | Aktualności',
    en: 'Logistics | Definition | Services | Warehousing | Insurance | News',
  },
  desc: {
    pl: 'Profesjonalne usługi logistyczne, magazynowanie, ubezpieczenia dedykowane dla szczególnych gałęzi przemysłu. Dowiedz się, jak dostosowana logistyka wpływa na optymalizację biznesu.',
    en: 'Professional logistic services, warehousing, and insurance dedicated to specific industries. Find out how adapted logistics affects business optimization.',
  },
  keywords: [
    'omida',
    'logistics',
    'logistyka',
    'magazynowanie',
    'ubezpieczenia',
  ],
}

export const intro = {
  title: {
    pl: 'Logistyka',
    en: 'Logistics',
  },
  desc: {
    pl: 'Profesjonalne usługi logistyczne, magazynowanie, ubezpieczenia dedykowane dla szczególnych gałęzi przemysłu. Dowiedz się, jak dostosowana logistyka wpływa na optymalizację biznesu.',
    en: 'Professional logistic services, warehousing, and insurance dedicated to specific industries. Find out how adapted logistics affects business optimization.',
  },
  button: {
    text: {
      pl: 'Czytaj więcej',
      en: 'Read more',
    },
    action: 'SCROLL',
  },
}

export const main = {
  body: [
    {
      headline: {
        pl: 'Czym jest logistyka?',
        en: 'What is logistics?',
      },
      texts: [
        {
          pl: '<span>Logistyka to proces <strong>planowania</strong>, <strong>realizacji</strong> i <strong>kontroli</strong> etapów przepływu surowców. To część łańcucha dostaw, która zajmuje się planowaniem, samym procesem i kontrolą sprawnego dostarczania towarów.</span>',
          en: '<span>Logistics is the process of <strong>planning</strong>, <strong>realization</strong> and <strong>control</strong> of the stages of the raw material flow. It is the part of the supply chain that deals with planning, the process itself and the control of the smooth delivery of goods.</span>',
        },
      ],
    },
    {
      headline: {
        pl: 'Jakie usługi logistyczne realizuje Omida Logistics?',
        en: 'What logistics services does Omida Logistics offer?',
      },

      texts: [
        {
          pl: '<span>Realizujemy całościową obsługę logistyczną sklepów <strong>e-commerce</strong>, <strong>logistykę kontraktową</strong>, a także <strong>centrum logistyczne</strong>. Dowiedz się jak nasz zespół może obsłużyć Twój <strong>e-Biznes</strong>.</span>',
          en: '<span>We offer comprehensive logistics services for <strong>e-commerce</strong> stores, <strong>contract logistics</strong>, as well as <strong>logistics center</strong>. Find out how our team can handle your <strong>e-Business</strong>.</span>',
        },
      ],
    },
  ],

  features: [
    {
      icon: 'boxes',
      subtitle: {
        pl: 'Profesjonalne',
        en: 'Professional',
      },
      title: {
        pl: 'Magazynowanie',
        en: 'Warehousing',
      },
      desc: {
        pl: 'Profesjonalne magazynowanie i usługi o wartości dodanej takie, jak przygotowywanie zestawów, kontrolę techniczną i jakościową oraz przepakowanie',
        en: 'Professional warehousing and value-added services, such as: preparation of sets, technical and quality controls and repackaging',
      },
    },
    {
      icon: 'truck',
      subtitle: {
        pl: 'Niższe koszty',
        en: 'Lower costs of ',
      },
      title: {
        pl: 'Logistyczne',
        en: 'Logistics',
      },
      desc: {
        pl: 'Niski poziom zapasów i mniej późnych wysyłek dzięki odpowiedniemu zarządzaniu',
        en: 'Low inventory levels and fewer late shipments thanks to appropriate management',
      },
    },
    {
      icon: 'document',
      subtitle: {
        pl: 'Obsługa zwrotów ',
        en: 'Handling returns',
      },
      title: {
        pl: 'Raportowanie',
        en: 'Reporting',
      },
      desc: {
        pl: 'Pełna i szyta na miarę potrzeb obsługę zwrotów z raportem z każdego zdarzenia',
        en: 'Full and made to measure handling returns with a report on each event',
      },
    },
    {
      icon: 'time',
      subtitle: {
        pl: 'Dane w czasie',
        en: 'Real time',
      },
      title: {
        pl: 'Rzeczywistym',
        en: 'Data',
      },
      desc: {
        pl: 'Kontrola zapasów i możliwość podjęcia właściwych decyzji już na etapie zamawiania',
        en: 'Inventory control and the opportunity of making the right decisions already at the stage of ordering',
      },
    },
    {
      icon: 'dollar',
      subtitle: {
        pl: 'Niższe koszty',
        en: 'Lower costs of',
      },
      title: {
        pl: 'Dystrybucji',
        en: 'Distribution',
      },
      desc: {
        pl: 'Koncentrujemy Twój wolumen wysyłkowy, który pozwoli znacznie obniżyć koszty dostaw',
        en: 'We concentrate your shipping volume, which will significantly reduce your delivery costs',
      },
    },
    {
      icon: 'unload',
      subtitle: {
        pl: 'Integracja z firmami',
        en: 'Integration with',
      },
      title: {
        pl: 'Kurierskimi',
        en: 'Courier companies',
      },
      desc: {
        pl: 'Zapewniamy integrację z firmami kurierskimi, pocztą oraz paczkomatami. Konkurencyjne ceny, dzięki współpracy z dużym operatorem',
        en: 'We provide integration with courier companies, post and parcel machines. Competitive prices, thanks to the cooperation with a large operator',
      },
    },
    {
      icon: 'settings',
      subtitle: {
        pl: 'Dostosowana',
        en: 'Customized',
      },
      title: {
        pl: 'Personalizacja',
        en: 'Personalization',
      },
      desc: {
        pl: 'Realizujemy personalizację na życzenie (m.in. składanie zestawów, dołączanie insertów, customizacja opakowań, a nawet grawerowanie laserowe)',
        en: 'We offer personalization on request (e.g. assembling sets, attaching inserts, packaging customization, and even laser engraving)',
      },
    },
    {
      icon: 'server',
      subtitle: {
        pl: 'System',
        en: 'System',
      },
      title: {
        pl: 'e-WMS',
        en: 'e-WMS',
      },
      desc: {
        pl: 'Pracujemy na oprogramowaniu informatycznym, które usprawnia system zarządzania magazynem',
        en: 'We are using computer software, which streamlines the inventory management system',
      },
    },
  ],
}
